<template>
  <view-item>
    <div class="h-full flex flex-col">
      <div class="py-6 flex items-center gap-4">
        <img src="@root/assets/icon-workbench.png" class="w-6" />
        <h5>工作台</h5>
      </div>

      <en-card class="flex-1 overflow-auto" body-class="h-full">
        <en-scrollbar view-class="h-full">
          <div class="grid grid-cols-2 gap-y-4">
            <span class="col-span-2 font-semibold">{{ store.user.branch?.name }}</span>

            <div class="flex gap-4">
              <span>{{ time.message }}</span>
              <span>{{ time.date }}</span>
            </div>

            <div class="pr-5 flex justify-end gap-12">
              <en-dropdown trigger="click">
                <div class="inline-flex items-center gap-1 cursor-pointer">
                  <span>其他产品</span>
                  <en-icon>
                    <icon-arrow-down></icon-arrow-down>
                  </en-icon>
                </div>
                <template #dropdown>
                  <en-dropdown-item>云报价</en-dropdown-item>
                </template>
              </en-dropdown>

              <en-popover
                trigger="click"
                :offset="0"
                :show-arrow="false"
                pure
                placement="bottom-end"
                width="520"
                popper-style="--el-popover-padding: 0"
              >
                <div class="relative p-4 flex gap-4">
                  <img src="@root/assets/service-bg.png" class="w-50 h-50" />
                  <div class="flex-1 flex flex-col justify-around">
                    <div class="grid grid-cols-3">
                      <span class="font-medium">华东区域</span>
                      <span class="text-sm">张金雷</span>
                      <span class="text-sm">18846071800</span>
                    </div>
                    <div class="border-1 border-solid border-[#D9D9D9]"></div>
                    <div class="grid grid-cols-3">
                      <span class="font-medium">西南区域</span>
                      <span class="text-sm">戚伟科</span>
                      <span class="text-sm">13350515922</span>
                    </div>
                    <div class="border-1 border-solid border-[#D9D9D9]"></div>
                    <div class="grid grid-cols-3">
                      <span class="font-medium">北区区域</span>
                      <span class="text-sm">于鹏</span>
                      <span class="text-sm">13621167129</span>
                    </div>
                    <div class="border-1 border-solid border-[#D9D9D9]"></div>
                    <div class="grid grid-cols-3">
                      <span class="font-medium">软件安装</span>
                      <span class="text-sm">石鲁斌</span>
                      <span class="text-sm">15757111147</span>
                    </div>
                  </div>
                </div>
                <template #reference>
                  <div class="inline-flex items-center gap-1 cursor-pointer">
                    <span>服务支持</span>
                    <en-icon>
                      <icon-arrow-down></icon-arrow-down>
                    </en-icon>
                  </div>
                </template>
              </en-popover>

              <en-popover
                trigger="click"
                :offset="0"
                :show-arrow="false"
                pure
                placement="bottom-end"
                width="536"
                popper-style="--el-popover-padding: 0"
              >
                <div class="relative p-4 flex gap-6">
                  <img src="@root/assets/app-code.png" class="w-50 h-50" />
                  <div class="relative flex-1 flex flex-col justify-between">
                    <div class="flex flex-col gap-4">
                      <div class="font-medium">以诺行车管家APP</div>
                      <div>接车扫码、钣面勾选、维修检验、结算处理，更高效、更精准</div>
                    </div>
                    <img src="@root/assets/app-pf.png" class="w-26 h-10" />
                  </div>

                  <img src="@root/assets/app-bg.png" class="absolute bottom-0 right-6 w-28 h-33" />
                </div>
                <template #reference>
                  <div class="inline-flex items-center gap-1 cursor-pointer">
                    <span>App下载</span>
                    <en-icon>
                      <icon-arrow-down></icon-arrow-down>
                    </en-icon>
                  </div>
                </template>
              </en-popover>
            </div>
          </div>

          <div v-if="store.noteShow" class="bg-[#F5FBFF] rounded-xl mt-6 p-6 flex gap-10 font-semibold">
            <div class="flex flex-col gap-2">
              <span>更新日志</span>
              <span>{{ formatDate(note.data.launchDate) }}</span>
            </div>

            <div class="flex-1 flex flex-col gap-2 h-30 overflow-hidden">
              <span>{{ note.data.versionNumber }}版本更新通知</span>
              <span v-html="note.data.content"></span>
            </div>

            <div class="flex flex-col justify-between items-end">
              <en-icon @click="operation.close.click" class="cursor-pointer">
                <icon-close></icon-close>
              </en-icon>

              <router-link to="/setting/tenant/note" class="flex items-center">
                <en-button type="primary" text>
                  <span>查看更多</span>
                  <en-icon>
                    <icon-right></icon-right>
                  </en-icon>
                </en-button>
              </router-link>
            </div>
          </div>

          <div class="mt-12 grid grid-cols-2 gap-x-12 gap-y-6">
            <div class="rounded-xl p-4 flex flex-col gap-6" style="box-shadow: 3px 3px 12px 1px rgba(0, 0, 0, 0.0784313753247261)">
              <span>维修 /Maintain</span>
              <div class="flex gap-14">
                <en-button text @click="navigator.click('/service/business/workorder')">
                  <div class="flex gap-3 items-center">
                    <img src="@root/assets/icon-workorder.png" class="h-4 w-4" />
                    <span>工单</span>
                  </div>
                </en-button>

                <en-button text @click="navigator.click('/service/business/inspection')">
                  <div class="flex gap-3 items-center">
                    <img src="@root/assets/icon-inspection.png" class="h-4 w-4" />
                    <span>检测</span>
                  </div>
                </en-button>

                <en-button text @click="navigator.click('/service/business/quotation')">
                  <div class="flex gap-3 items-center">
                    <img src="@root/assets/icon-quotation.png" class="h-4 w-4" />
                    <span>估价</span>
                  </div>
                </en-button>
              </div>
            </div>

            <div class="row-span-5 bg-[#FAFAFA] rounded-xl px-12">
              <div class="flex justify-end pt-2">
                <en-icon @click="operation.refresh.click" class="cursor-pointer">
                  <icon-refresh></icon-refresh>
                </en-icon>
              </div>

              <div class="grid grid-cols-3 gap-20">
                <div class="h-134 flex flex-col gap-6">
                  <div class="px-4 py-6 border-b border-b-solid border-b-gray-200 flex justify-between">
                    <span>在场</span>
                    <span>{{ pending.paging.itemCount }}</span>
                  </div>

                  <en-scrollbar v-loading="pending.loading" view-class="px-4 flex flex-col gap-4">
                    <div
                      v-for="item of pending.data"
                      class="cursor-pointer flex justify-between hover:border-l-2 hover:border-l-solid hover:border-l-primary"
                      @mouseover="pending.item.mouseover(item)"
                      @mouseout="pending.item.mouseout"
                    >
                      <div class="px-2 flex flex-col gap-2">
                        <span>{{ item.plateNo }}</span>
                        <span v-show="pending.item.active?.id === item.id" class="text-xs text-gray">{{ item.serialNo }}</span>
                      </div>
                      <span v-show="pending.item.active?.id === item.id" class="text-primary" @click="pending.item.click(item)">处理</span>
                    </div>
                  </en-scrollbar>
                </div>

                <div class="h-134 flex flex-col gap-6">
                  <div class="px-4 py-6 border-b border-b-solid border-b-gray-200 flex justify-between">
                    <span>已结算</span>
                    <span>{{ settled.paging.itemCount }}</span>
                  </div>

                  <en-scrollbar v-loading="settled.loading" view-class="px-4 flex flex-col gap-4 overflow-auto">
                    <div
                      v-for="item of settled.data"
                      class="cursor-pointer flex justify-between hover:border-l-2 hover:border-l-solid hover:border-l-primary"
                      @mouseover="settled.item.mouseover(item)"
                      @mouseout="settled.item.mouseout"
                    >
                      <div class="px-2 flex flex-col gap-2">
                        <span>{{ item.plateNo }}</span>
                        <span v-show="settled.item.active?.id === item.id" class="text-xs text-gray">{{ item.serialNo }}</span>
                      </div>
                      <span v-show="settled.item.active?.id === item.id" class="text-primary" @click="settled.item.click(item)">处理</span>
                    </div>
                  </en-scrollbar>
                </div>

                <div class="h-134 flex flex-col gap-6">
                  <div class="px-4 py-6 border-b border-b-solid border-b-gray-200 flex justify-between">
                    <span>提醒</span>
                    <span>{{ dashboard.total }}</span>
                  </div>

                  <en-scrollbar view-class="px-4 flex flex-col gap-4 overflow-auto">
                    <div
                      class="px-2 cursor-pointer flex items-center justify-between hover:border-l-2 hover:border-l-solid hover:border-l-primary"
                      @mouseover="dashboard.item.mouseover('insuranceExpireCount')"
                      @mouseout="dashboard.item.mouseout"
                    >
                      <div class="flex items-center">
                        <span>保险到期</span>
                        <span>{{ dashboard.data.insuranceExpireCount }}</span>
                      </div>
                      <span v-show="dashboard.item.active === 'insuranceExpireCount'" class="text-primary">处理</span>
                    </div>
                    <div
                      class="px-2 cursor-pointer flex items-center justify-between hover:border-l-2 hover:border-l-solid hover:border-l-primary"
                      @mouseover="dashboard.item.mouseover('maintainExpireCount')"
                      @mouseout="dashboard.item.mouseout"
                    >
                      <div class="flex items-center">
                        <span>保养到期</span>
                        <span>{{ dashboard.data.maintainExpireCount }}</span>
                      </div>
                      <span v-show="dashboard.item.active === 'maintainExpireCount'" class="text-primary">处理</span>
                    </div>
                    <div
                      class="px-2 cursor-pointer flex items-center justify-between hover:border-l-2 hover:border-l-solid hover:border-l-primary"
                      @mouseover="dashboard.item.mouseover('annualInspectionExpireCount')"
                      @mouseout="dashboard.item.mouseout"
                    >
                      <div class="flex items-center">
                        <span>年检到期</span>
                        <span>{{ dashboard.data.annualInspectionExpireCount }}</span>
                      </div>
                      <span v-show="dashboard.item.active === 'annualInspectionExpireCount'" class="text-primary">处理</span>
                    </div>
                    <div
                      class="px-2 cursor-pointer flex items-center justify-between hover:border-l-2 hover:border-l-solid hover:border-l-primary"
                      @mouseover="dashboard.item.mouseover('businessOpportunityCount')"
                      @mouseout="dashboard.item.mouseout"
                    >
                      <div class="flex items-center">
                        <span>服务提醒总数</span>
                        <span>{{ dashboard.data.businessOpportunityCount }}</span>
                      </div>
                      <span v-show="dashboard.item.active === 'businessOpportunityCount'" class="text-primary">处理</span>
                    </div>
                    <div
                      class="px-2 cursor-pointer flex items-center justify-between hover:border-l-2 hover:border-l-solid hover:border-l-primary"
                      @mouseover="dashboard.item.mouseover('schemeExpireCount')"
                      @mouseout="dashboard.item.mouseout"
                    >
                      <div class="flex items-center">
                        <span>优惠券到期</span>
                        <span>{{ dashboard.data.schemeExpireCount }}</span>
                      </div>
                      <span v-show="dashboard.item.active === 'schemeExpireCount'" class="text-primary">处理</span>
                    </div>
                    <div
                      class="px-2 cursor-pointer flex items-center justify-between hover:border-l-2 hover:border-l-solid hover:border-l-primary"
                      @mouseover="dashboard.item.mouseover('serviceReturnVisitCount')"
                      @mouseout="dashboard.item.mouseout"
                    >
                      <div class="flex items-center">
                        <span>回访列表</span>
                        <span>{{ dashboard.data.serviceReturnVisitCount }}</span>
                      </div>
                      <span v-show="dashboard.item.active === 'serviceReturnVisitCount'" class="text-primary">处理</span>
                    </div>
                    <div
                      class="px-2 cursor-pointer flex items-center justify-between hover:border-l-2 hover:border-l-solid hover:border-l-primary"
                      @mouseover="dashboard.item.mouseover('nearTheBirthdayCount')"
                      @mouseout="dashboard.item.mouseout"
                    >
                      <div class="flex items-center">
                        <span>生日提醒</span>
                        <span>{{ dashboard.data.nearTheBirthdayCount }}</span>
                      </div>
                      <span v-show="dashboard.item.active === 'nearTheBirthdayCount'" class="text-primary">处理</span>
                    </div>
                    <div
                      class="px-2 cursor-pointer flex items-center justify-between hover:border-l-2 hover:border-l-solid hover:border-l-primary"
                      @mouseover="dashboard.item.mouseover('awaitingReservationCount')"
                      @mouseout="dashboard.item.mouseout"
                    >
                      <div class="flex items-center">
                        <span>预约提醒</span>
                        <span>{{ dashboard.data.awaitingReservationCount }}</span>
                      </div>
                      <span v-show="dashboard.item.active === 'awaitingReservationCount'" class="text-primary">处理</span>
                    </div>
                    <div
                      class="px-2 cursor-pointer flex items-center justify-between hover:border-l-2 hover:border-l-solid hover:border-l-primary"
                      @mouseover="dashboard.item.mouseover('complaintCount')"
                      @mouseout="dashboard.item.mouseout"
                    >
                      <div class="flex items-center">
                        <span>投诉提醒</span>
                        <span>{{ dashboard.data.complaintCount }}</span>
                      </div>
                      <span v-show="dashboard.item.active === 'complaintCount'" class="text-primary">处理</span>
                    </div>
                    <div
                      class="px-2 cursor-pointer flex items-center justify-between hover:border-l-2 hover:border-l-solid hover:border-l-primary"
                      @mouseover="dashboard.item.mouseover('inventoryWarmingCount')"
                      @mouseout="dashboard.item.mouseout"
                    >
                      <div class="flex items-center">
                        <span>安全库存提醒</span>
                        <span>{{ dashboard.data.inventoryWarmingCount }}</span>
                      </div>
                      <span v-show="dashboard.item.active === 'inventoryWarmingCount'" class="text-primary">处理</span>
                    </div>
                  </en-scrollbar>
                </div>
              </div>
            </div>

            <div class="rounded-xl p-4 flex flex-col gap-6" style="box-shadow: 3px 3px 12px 1px rgba(0, 0, 0, 0.0784313753247261)">
              <span>配件 /Accessories</span>
              <div class="flex gap-14">
                <en-button text @click="navigator.click('/accessory/inventory/goods')">
                  <div class="flex gap-3 items-center">
                    <img src="@root/assets/icon-storage.png" class="h-4 w-4" />
                    <span>库房</span>
                  </div>
                </en-button>

                <en-button text @click="navigator.click('/accessory/stock/in/purchase')">
                  <div class="flex gap-3 items-center">
                    <img src="@root/assets/icon-storage.png" class="h-4 w-4" />
                    <span>入库</span>
                  </div>
                </en-button>

                <en-button text @click="navigator.click('/accessory/stock/out/sale')">
                  <div class="flex gap-3 items-center">
                    <img src="@root/assets/icon-storage-out.png" class="h-4 w-4" />
                    <span>出库</span>
                  </div>
                </en-button>
              </div>
            </div>

            <div class="rounded-xl p-4 flex flex-col gap-6" style="box-shadow: 3px 3px 12px 1px rgba(0, 0, 0, 0.0784313753247261)">
              <span>财务 /Finance</span>
              <div class="flex gap-14">
                <en-button text @click="navigator.click('/settlement/receivable')">
                  <div class="flex gap-3 items-center">
                    <img src="@root/assets/icon-receivable.png" class="h-4 w-4" />
                    <span>收款</span>
                  </div>
                </en-button>

                <en-button text @click="navigator.click('/settlement/payable')">
                  <div class="flex gap-3 items-center">
                    <img src="@root/assets/icon-receipt.png" class="h-4 w-4" />
                    <span>付款</span>
                  </div>
                </en-button>
              </div>
            </div>

            <div class="rounded-xl p-4 flex flex-col gap-6" style="box-shadow: 3px 3px 12px 1px rgba(0, 0, 0, 0.0784313753247261)">
              <span>客户 /CRM</span>
              <div class="flex gap-14">
                <en-button text @click="navigator.click('/client/market/coupon')">
                  <div class="flex gap-3 items-center">
                    <img src="@root/assets/icon-coupon.png" class="h-4 w-4" />
                    <span>卡券</span>
                  </div>
                </en-button>

                <en-button text @click="navigator.click('/client/market/coupon')">
                  <div class="flex gap-3 items-center">
                    <img src="@root/assets/icon-check.png" class="h-4 w-4" />
                    <span>核验</span>
                  </div>
                </en-button>

                <en-button text @click="navigator.click('/query/data')">
                  <div class="flex gap-3 items-center">
                    <img src="@root/assets/icon-data.png" class="h-4 w-4" />
                    <span>数据</span>
                  </div>
                </en-button>

                <en-button text @click="navigator.click('/client/customer/topup')">
                  <div class="flex gap-3 items-center">
                    <img src="@root/assets/icon-account.png" class="h-4 w-4" />
                    <span>账户</span>
                  </div>
                </en-button>
              </div>
            </div>
          </div>

          <div class="p-6 flex flex-col gap-2 text-[#939393] text-xs">
            <span>©2016-现在 以诺行 版权所有</span>
            <span>浙ICP备 16038648号-1</span>
            <span>浙公网安备 33011002015331号</span>
            <span>电话：400-996-0171</span>
          </div>
        </en-scrollbar>
      </en-card>
    </div>
  </view-item>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { Right as IconRight, ArrowDown as IconArrowDown, Refresh as IconRefresh, Close as IconClose } from '@element-plus/icons-vue'

let interval: NodeJS.Timer | null

const accessMap: Record<string, string> = {
  '/service/business/workorder': 'SERVICE_QUERY',
  '/service/business/inspection': 'SERVICE_QUERY',
  '/service/business/quotation': 'SERVICE_QUERY',
  '/accessory/inventory/goods': 'INVENTORY_WAREHOUSE',
  '/accessory/stock/in/purchase': 'PURCHASE_IN_QUERY',
  '/accessory/stock/out/sale': 'PURCHASE_IN_QUERY',
  '/settlement/receivable': 'RECEIVABLE_QUERY',
  '/settlement/payable': 'RECEIVABLE_QUERY',
  '/client/market/coupon': 'MALL_COUPON_QUERY,MALL_COUPON_INSTANCE_QUERY,MALL_COUPON_INSTANCE_CHECK',
  '/query/data': 'DATA_QUERY',
  '/client/customer/topup': 'TOPUP_QUERY,CHARGEABLE_QUERY'
}

export default factory({
  components: { IconRight, IconArrowDown, IconRefresh, IconClose },

  config: {
    children: {
      operation: {
        close: {
          click() {
            this.store.changeNoteShow()
          }
        },
        refresh: {
          click() {
            this.pending.get()
            this.settled.get()
          }
        }
      },
      time: {
        message: '',
        date: dayjs().format('YYYY年MM月DD日')
      },
      note: {
        show: true,
        ajax: {
          get: {
            action: 'GET /enocloud/common/release/note',
            data: 'object',
            loading: true
          }
        }
      },
      navigator: {
        click(path: string) {
          const codes = accessMap[path].split(',')
          if (
            codes.reduce((valid, code) => {
              if (!valid) return valid
              return this.store.accessRightsHash[code]
            }, true)
          ) {
            this.router.push(path)
          }
        }
      },
      pending: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: 'IM' }
            }
          }
        },
        children: {
          item: {
            active: null as EnocloudServiceDefinitions['ServiceQueryDto'] | null,
            mouseover(item: EnocloudServiceDefinitions['ServiceQueryDto']) {
              this.pending.item.active = item
            },
            mouseout() {
              this.pending.item.active = null
            },
            click(item: EnocloudServiceDefinitions['ServiceQueryDto']) {
              this.router.push('/service/business/workorder', (vm) => {
                vm.form.data.id = item.id
                vm.form.get()
              })
            }
          }
        }
      },
      settled: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: 'PD' }
            }
          }
        },
        children: {
          item: {
            active: null as EnocloudServiceDefinitions['ServiceQueryDto'] | null,
            mouseover(item: EnocloudServiceDefinitions['ServiceQueryDto']) {
              this.settled.item.active = item
            },
            mouseout() {
              this.settled.item.active = null
            },
            click(item: EnocloudServiceDefinitions['ServiceQueryDto']) {
              this.router.push('/service/business/workorder', (vm) => {
                vm.form.data.id = item.id
                vm.form.get()
              })
            }
          }
        }
      },
      dashboard: {
        ajax: {
          get: {
            action: 'GET /enocloud/dashboard',
            data: 'object',
            loading: true
          }
        },
        computed: {
          total() {
            return Object.keys(this.dashboard.data).reduce((total, key) => {
              if (
                [
                  'insuranceExpireCount',
                  'maintainExpireCount',
                  'annualInspectionExpireCount',
                  'businessOpportunityCount',
                  'schemeExpireCount',
                  'serviceReturnVisitCount',
                  'nearTheBirthdayCount',
                  'awaitingReservationCount',
                  'complaintCount',
                  'inventoryWarmingCount'
                ].includes(key) &&
                key in this.dashboard.data
              ) {
                total += (this.dashboard.data as any)[key] > 0 ? 1 : 0
              }

              return total
            }, 0)
          }
        },
        children: {
          item: {
            active: '',
            mouseover(item: string) {
              this.dashboard.item.active = item
            },
            mouseout() {
              this.dashboard.item.active = ''
            }
          }
        }
      }
    }
  },

  mounted() {
    interval = setInterval(() => (this.time.message = dayjs().format('HH:MM')), 1000)
    this.note.get()
    this.pending.get()
    this.settled.get()
    this.dashboard.get()
  },

  unmounted() {
    if (interval) {
      clearInterval(interval)
      interval = null
    }
  }
})
</script>
